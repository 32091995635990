.tabList {
  @apply sm:block z-10 lg:z-0 mt-[-16px] lg:mt-4;
}

.imageContainer {
  @apply flex justify-center sm:flex-row space-x-[16px];
}

.group {
  @apply relative h-[50px] w-[50px] lg:w-[75px] lg:h-[75px] cursor-pointer rounded-md hover:ring-2 hover:ring-primary-button-background hover:border-transparent focus:outline-none;
}

.group img {
  @apply rounded-md;
}

.activeBorder {
  @apply ring-2 ring-primary-button-background border-transparent;
}

.inactiveBorder {
  @apply ring-2 ring-gray-200;
}
