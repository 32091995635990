.imageLink {
  @apply pointer-events-auto;
}

.imageContainer {
  @apply relative w-[630px] h-[630px] pointer-events-none md:pointer-events-auto contents;
}

.imageContainer img,
.imageLink img {
  @apply border border-spring-neutral-20 md:rounded-2xl;
}

.imageContainer img {
  @apply object-contain;
}

.imageLink img {
  @apply object-contain h-full absolute w-full;
}

.dialog {
  @apply fixed inset-0 z-50 flex items-center justify-center bg-white;
}

.dialogPanel {
  @apply fixed inset-0 flex items-center justify-center;
}

.closeButton {
  @apply absolute right-3 top-4 z-10;
}

.closeButtonIcon {
  @apply w-7 h-7 text-gray-400 hover:text-gray-700;
}

.leftArrow {
  @apply absolute top-1/2 left-3 -translate-y-1/2 focus:outline-none text-gray-400 hover:text-gray-700;
}

.rightArrow {
  @apply absolute top-1/2 right-3 -translate-y-1/2 focus:outline-none text-gray-400 hover:text-gray-700;
}

.inactive-border {
  @apply border-spring-neutral-20;
}

.arrowIcon {
  @apply w-8 h-8;
}

.imageSize {
  @apply relative w-[1200px] h-[1200px] max-w-full max-h-full;
}

.dialogPanel img {
  @apply object-none p-16;
}

.imageSize img {
  @apply object-contain h-full;
}
