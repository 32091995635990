.thumbNavigation {
  @apply flex justify-center h-[60px] -mt-6 z-10 pt-1;
}

.thumbNavigation [class*='swiper-wrapper'] {
  @apply w-auto disabled:transform;
}

.thumbItem {
  @apply mr-4 w-[50px] h-[52px] !important;
}

.thumbItem img {
  @apply rounded-md ring-2 ring-gray-200;
}

.thumbNavigation [class*='thumb-active'] img {
  @apply ring-2 ring-spring-blue border-transparent rounded-md;
}

.dialogPanel {
  @apply fixed inset-0 flex items-center justify-center;
}

.zoomedImage {
  @apply relative max-w-full max-h-full;
}

.dialog {
  @apply fixed inset-0 z-50 flex items-center justify-center bg-white;
}

.closeButton {
  @apply absolute right-3 top-4 z-10;
}

.closeButtonIcon {
  @apply w-7 h-7 text-gray-400 hover:text-gray-700;
}
